.time-off-card-container {
  padding: 1rem 2rem;
  .setting-backbutton {
    margin: 0 0 1rem;
    width: 6%;
    h5 {
      padding: 0 0 0 1rem;
    }
  }
}
.time-off-card-main-wrapper {
  background: #fff;
  padding: 1.5rem 2.5rem;
}
.time-off-title-wrapper {
  h3 {
    font-family: "Lato";
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.025em;
    text-align: left;
    color: #09090b;
    margin: 0;
  }
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #71717a;
    margin: 6px 0;
  }
}
.time-off-main-content-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 3rem;
}
.time-off-calender-wrapper {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.time-off-selected-dates-wrapper {
  display: flex;
  width: 50%;
  // overflow-y: scroll;
  // border: 1px solid #e2e8f0;
  // box-shadow: 0px 4px 6px 0px #00000017;
  // border-radius: 6px;
}
.time-off-common-border-wrapper {
  border: 1px solid #e2e8f0;
  box-shadow: 0px 4px 6px 0px #00000017;
  border-radius: 6px;
}
.calender-title-wrapper {
  padding: 0 1.5rem 1rem;
  border-radius: 6px;
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.025em;
    color: #09090b;
    margin: 0;
    border-bottom: 1px solid #e4e4e7;
    padding: 1.5rem 0rem 1rem;
  }
}
.calender-content-wrapper {
  padding: 0 1.5rem 1rem;
  .rdrDateRangePickerWrapper {
    border: 1px solid #e4e4e7;
    border-radius: 6px;
    // width: 75%;
  }
  .rdrCalendarWrapper {
    border-radius: 6px;
  }
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
  // .rdrMonthAndYearWrapper {
  //   display: none;
  // }
  .rdrEndEdge {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    right: 2px;
  }
  .rdrStartEdge {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    left: 2px;
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: #2773b1;
  }
  .rdrDayNumber {
    span {
      font-family: "Lato";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #09090b;
      &::after {
        display: none;
      }
    }
  }
  .rdrMonthName {
    text-align: center;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #09090b;
    display: none;
  }
  // .rdrMonthAndYearWrapper {
  //   button {
  //     display: none;
  //   }
  // }
  .rdrMonthAndYearPickers select {
    font-weight: 700;
    line-height: 2.667em;
    font-size: 14px;
    background: #fff;
    // appearance: auto;
    // -webkit-appearance: auto;
    padding: 0px;
    width: 70px;
    &:hover {
      background: #fff;
    }
  }

  // .rdrDays {
  //   button {
  //     width: 36px;
  //     height: 36px;
  //   }
  // }
  .rdrWeekDays {
    font-family: "Lato";
    font-size: 12.8px;
    font-weight: 400;
    line-height: 20px;
    color: #71717a;
  }
  // .rdrMonths {
  // }
  // .rdrDayToday {
  //   background: #2773b1;
  //   width: 36px;
  //   height: 36px;
  //   border-radius: 6px;
  // }
  // .rdrInRange {
  //   background: #f4f4f5;
  // }
}
.custom-date-range-picker {
  .rdrMonthPicker select option:checked {
    // color: #fff;
    // background-color: #2773b1;
    &:hover {
      color: #fff;
      background-color: #2773b1;
    }
  }

  .rdrYearPicker select option:checked {
    // color: #fff;
    // background-color: #2773b1;
    &:hover {
      color: #fff;
      background-color: #2773b1;
    }
  }
}
.custom-date-range-picker {
  .rdrMonthPicker select option:checked,
  .rdrYearPicker select option:checked {
    color: #fff;
    background-color: #2773b1;
    &:hover {
      color: #fff;
      background-color: #2773b1;
    }
  }
}
.leave-type-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
.leave-title-wrapper {
  padding: 0 0 0.5rem;
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #09090b;
    span {
      color: red;
    }
  }
}
.calender-container {
  margin: 0 0 1.5rem;
}
.hours-text-wrapper {
  display: flex;
  justify-content: space-between;

  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #09090b;
    margin: 0.5rem 0;
  }
}
.comment-section-wrapper {
  margin: 1.5rem 0 0;
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #09090b;
    span {
      color: red;
    }
  }
  textarea {
    resize: none;
    border: 1px solid #e4e4e7;
    box-shadow: 0px 1px 2px 0px #0000000d;
    width: 100%;
    border-radius: 4px;
    height: 80px;
    color: #18181b;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding: 4px 12px;
    &:focus-visible {
      outline: none;
    }
  }
}
.text-area-length {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #71717a;
  margin: 0.5rem 0 0;
}
.no-date-selecte-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h5 {
    font-family: "Lato";
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #09090b;
  }
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #71717a;
  }
}
.selected-dates-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.selected-dates-container {
  display: flex;
  width: 100%;
}
.time-off-footer-wrapper {
  display: flex;
  justify-content: end;
  box-shadow: 1px 2px 11.6px 0px #00000026;
  padding: 1.5rem 2.5rem;
  background: #fff;
}
.footer-btn-wrapper {
  display: flex;
  justify-content: space-between;
}
.cance-btn-wrapper {
  border-radius: 6px;
  background: #f4f4f5;
  color: #18181b;
  border: none;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0.75rem 2rem;
  margin: 0 1rem;
  text-transform: uppercase;
}
.submit-btn-wrapper {
  border-radius: 6px;
  background-color: #2773b1;
  color: #fff;
  border: none;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  width: 150px;
}
.disabled {
  background: #c5c5c5;
  border: 1px solid #c5c5c5;
  cursor: not-allowed;
}
.selected-dates-tiles-container::-webkit-scrollbar {
  width: 7px;
}

.selected-dates-tiles-container::-webkit-scrollbar-thumb {
  background: #e4e4e7;
  border-radius: 25px;
  height: 45px !important;
  opacity: 0;
}

.selected-dates-tiles-container::-webkit-scrollbar-track {
  background: transparent;
}

.selected-dates-tiles-container {
  width: 100%;
  padding: 1.5rem 1.5rem 1rem;
}
.selected-date-client-tiles {
  height: 800px;
  overflow-y: scroll;
}
.selected-date-employee-tiles {
  height: 725px;
  overflow-y: scroll;
}
.selected-dates-tiles-container::-webkit-scrollbar-button {
  height: 0;
  display: none;
}
// .selected-dates-tiles-container {
//   width: 100%;
//   padding: 1.5rem 1.5rem 1rem;
//   height: 880px;
//   overflow-y: scroll;
// }
.selected-date-range-title {
  border-bottom: 1px solid #e4e4e7;
  h5 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.025em;
    color: #09090b;
    margin: 0 0 0.75rem;
  }
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #71717a;
    margin: 0.5rem 0;
  }
}
.selected-card-tiles-main-wrapper {
  padding: 1rem 0 0;
}
.selected-card-wrapper {
  box-shadow: 0px 1px 2px 0px #0000001a;
  box-shadow: 0px 1px 3px 0px #0000001a;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0.75rem;
  border-radius: 8px;
  align-items: center;
  h5 {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #09090b;
    margin: 0;
  }
  h3 {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;

    span {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.hours-selector {
  display: flex;
  align-items: center;
  width: 64%;
  justify-content: space-between;
  p {
    color: #71717a;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
}
.hours-text-sub-wrapper {
  display: flex;
  // border-radius: 6px;
  // border: 1px solid #e4e4e7;
  box-shadow: 0px 1px 2px 0px #0000000d;
  width: 54px;
  height: 36px;
  input {
    width: 90%;
    border: none;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #09090b;
    border-left: 1px solid #e4e4e7;
    border-bottom: 1px solid #e4e4e7;
    border-top: 1px solid #e4e4e7;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    &:focus-visible {
      outline: none;
    }
  }
}
@-moz-document url-prefix() {
  .hours-text-sub-wrapper {
    width: 62px;
    input {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right: 1px solid #e4e4e7;
    }
  }
}
.hours-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 5px;
  align-items: center;
  padding: 0 5px 0 0;
  border-right: 1px solid #e4e4e7;
  border-bottom: 1px solid #e4e4e7;
  border-top: 1px solid #e4e4e7;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  img {
    width: 10px;
  }
}
@-moz-document url-prefix() {
  .hours-btn-wrapper {
    display: none;
  }
}
.select-card-sub-wrapper {
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
.close-icon-wrapper {
  img {
    cursor: pointer;
  }
}
.error-text {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  color: #ff0000;
  margin: 1rem 0;
}
.negative-wrapper {
  color: #ff0000 !important;
}
.error-message {
  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #ff0000;
    margin: 0.5rem 0 0;
  }
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #ff0000;
  margin: 0.5rem 0 0;
}
.carryover-wrapper {
  border: 1px solid #e7e9ec;
  border-radius: 6px;
  margin: 6px 0;
}
.carryover-title-wrapper {
  background: #e7e9ec;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  h3 {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0 0 0 6px;
    color: #09090b;
    span {
      font-family: "Lato";
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #2773b1;
    }
  }
}
.carryover-sub-text-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  border-top: 1px solid #e2e8f0;
  p {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #09090b;
    margin: 0.5rem 0;
  }
}
@media screen and (max-width: 1100px) {
  .selected-card-wrapper {
    flex-direction: column;
    grid-gap: 10px;
    padding: 0.5rem 0.75rem;
  }
  .select-card-sub-wrapper {
    width: 70%;
  }
}
@media screen and (max-width: 1100px) {
  .selected-card-wrapper {
    flex-direction: column;
    grid-gap: 10px;
    padding: 0.5rem 0.75rem;
  }
  .hours-selector {
    width: auto;
    grid-gap: 6px;
  }
  .select-card-sub-wrapper {
    width: 70%;
  }
  .time-off-main-content-wrapper {
    flex-direction: column;
    grid-gap: 10px;
  }
  .time-off-calender-wrapper {
    width: 100%;
  }
  .time-off-selected-dates-wrapper {
    width: 100%;
  }
  .selected-dates-tiles-container {
    height: auto;
  }
  // .calender-content-wrapper {
  //   .rdrDays {
  //     justify-content: center;
  //   }
  //   .rdrMonth {
  //     width: 49%;
  //   }
  //   .rdrWeekDays {
  //     width: 100%;
  //   }
  //   .rdrMonths {
  //     justify-content: center;
  //   }
  // }
}
@media screen and (max-width: 768px) {
  .time-off-card-container {
    padding: 1rem;
  }
  .time-off-card-main-wrapper {
    padding: 1rem;
  }
  .time-off-main-content-wrapper {
    flex-direction: column;
    grid-gap: 10px;
  }

  .leave-type-wrapper {
    padding: 1rem 0.5rem;
  }
  .selected-dates-tiles-container {
    padding: 1rem 0.5rem 1rem 1rem;
  }
  .calender-content-wrapper {
    display: flex;
    justify-content: center;
  }
  // .calender-content-wrapper {
  //   .rdrDateRangePickerWrapper {
  //     width: 100%;
  //   }
  //   .rdrWeekDays {
  //     width: 100%;
  //   }
  //   .rdrMonth {
  //     width: 100%;
  //   }
  // }
}
@media screen and (max-width: 600px) {
  .time-off-card-container {
    padding: 1rem 0;
  }
  .select-card-sub-wrapper {
    width: 95%;
  }
  .time-off-footer-wrapper {
    padding: 0.5rem;
    justify-content: center;
  }
  .cance-btn-wrapper {
    margin: 0;
  }
  .footer-btn-wrapper {
    justify-content: space-evenly;
    width: 100%;
  }
  .calender-content-wrapper {
    padding: 0rem 0 1rem;
  }
  .no-date-selecte-text {
    padding: 1rem;
    p {
      margin: 0;
    }
  }
  .calender-title-wrapper {
    padding: 0 0.5rem 1rem;
  }
  .calender-content-wrapper {
    .rdrDateRangePickerWrapper {
      width: 95%;
    }
    .rdrCalendarWrapper {
      width: 100%;
    }
    .rdrMonths {
      justify-content: center;
    }
  }
  .comment-section-wrapper {
    margin: 0;
    padding: 1rem 0 0 ;
  }
  .selected-card-wrapper {
    padding: 0.5rem 0.75rem 0.75rem;
    align-items: start;
  }
}
@media screen and (max-width: 400px) {
  .calender-content-wrapper {
    .rdrMonth {
      width: -webkit-fill-available;
    }
  }
}
@media screen and (max-width: 320px) {
  .time-off-card-main-wrapper {
    padding: 1rem 12px;
  }
}
